import React from "react";

const Header = (props) => {
    return (
        <header className="header">
        <div  className="container-fluid" >
            <div className="row header-center">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/home/EyVrAxBTudc=">
                                <span className="img-logo"></span>
                                <span className="text-logo">Avalon Nail Spa</span>
                            </a>
                        </div>
                    </div>

                    <div className="header-right">
                        <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                            <div className="navbar-collapse">
                                <ul className="navbar-nav mr-auto no-bullets">
                                <li className="menu-item">
                                                <img src={require('../../assets/images/qr1.png')} className="qr-img" height="70" alt="" />
                                            </li>
                                            <li className="menu-item">
                                                <img src={require('../../assets/images/qr2.png')} className="qr-img" height="70" alt="" />
                                            </li>
                                            <li className="menu-item">
                                                <img src={require('../../assets/images/qr3.png')} className="qr-img" height="70" alt="" />
                                            </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
            </div>
        </div>
    </header>
       
    );
}
export default Header;