import React from "react";

const Footer = (props) => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4  menu-footer-one">
                            <a href="/home/EyVrAxBTudc=" className="logo-footer">
                                <img src={require('../../assets/images/nailsspalogo.png')} alt="" />
                            </a>
                            <ul className="footer-list no-bullets">
                                <li>
                                    <span className="icon">
                                        <i className="ti-home" aria-hidden="true"></i>
                                    </span>
                                    <p>Address: 13574 University Blvd Ste. 900,Sugar Land, TX 77479</p>
                                </li>
                                <li>
                                    <span className="icon">
                                        <i className="fa fa-volume-control-phone" aria-hidden="true"></i>
                                    </span>
                                    <p>
                                        Phone:
                                        <a href="tel:(+84)934 323 882">(281) 800-2742</a>
                                    </p>
                                </li>
                                <li>
                                    <span className="icon">
                                        <i className="ti-email" aria-hidden="true"></i>
                                    </span>
                                    <p>
                                        Email :
                                        <a href="mailto: arianailbarsugarland@gmail.com">arianailbarsugarland@gmail.com</a>
                                    </p>
                                </li>
                                <li>
                                    <span className="icon">
                                        <i className="ti-world" aria-hidden="true"></i>
                                    </span>
                                    <p>
                                        Website :
                                        <a href="https://avalonnailspasugarland.com">avalonnailspasugarland.com</a>
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4 menu-footer menu-footer-two">
                            <h3 className="menu-link">Location</h3>
                            <div className="footer-list content">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4269.379977513071!2d-95.650042!3d29.582797700000008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640e153f203f79b%3A0xc149bd220b977f76!2s13574%20University%20Blvd%20Ste.%20900%2C%20Sugar%20Land%2C%20TX%2077479%2C%20Hoa%20K%E1%BB%B3!5e1!3m2!1svi!2s!4v1733715135854!5m2!1svi!2s"
                                 width="300" height="250">
                                    <p>Your browser does not support iframes.</p>
                                 </iframe>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4 menu-footer menu-footer-three">
                            <h3 className="menu-link">Hours of Operation</h3>
                            <div className="footer-list content">
                                <ul className="homehours">
                                    <li>Mon-Sat: 09:30 am - 7:00 pm </li>
                                    <li>Sun: 11:00 am - 5:00 pm </li>
                                </ul>
                                <div className="weaccept">
                                    <h3>We Accept:</h3>
                                    <div className="lstbank">
                                        <img src={require('../../assets/images/social/visa.png')} alt="visa" />
                                        <img src={require('../../assets/images/social/master.png')} alt="master" />
                                        <img src={require('../../assets/images/social/discover.png')} alt="discover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <section id="footer-info" className="footer-info">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="footer-bottom-contact">
                                    Copyrights © 2024 by <a target="_blank" href="/home/EyVrAxBTudc=">Avalon Nail Spa.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Footer;