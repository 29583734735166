import React, { useEffect, useState,  useRef  } from "react";
import { HOSTNAME } from "../utils/constants/SystemVars";
import { _fetchData } from '../utils/CallAPI';
import { useParams } from "react-router-dom";

const Home = (props) => {
    const { KeyAPI } = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [isLoadFinish, setIsLoadFinish] = useState(false);
    const [isServicesTabsFixed, setIsServicesTabsFixed] = useState(false);
    const topLimit = 300;
    const ultimateOffset = 200;
    const offset = 115;
    const servicesTabsRef = useRef(null);
    const onePageNavRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
          // Xử lý sự kiện cuộn
          if (window.scrollY > offset) {
            setIsServicesTabsFixed(true);
          } else {
            setIsServicesTabsFixed(false);
          }
    
          // Xử lý các liên kết trong one page navigation
          const links = onePageNavRef.current.querySelectorAll('a');
          let current = null;
    
          const getHeader = (top) => {
            let last = links[0];
            if (top < topLimit) {
              return last;
            }
    
            for (let i = 0; i < links.length; i++) {
              const link = links[i];
              const href = link.getAttribute('href');
              if (href.startsWith('#') && href.length > 1) {
                const anchor = document.querySelector(href);
                if (anchor) {
                  const offset = anchor.offsetTop;
                  if (top < offset - ultimateOffset) {
                    return last;
                  }
                  last = link;
                }
              }
            }
            return last;
          };
    
          const top = window.scrollY;
          const currentHeader = getHeader(top);
    
          if (currentHeader !== current) {
            links.forEach(link => link.classList.remove('active'));
            currentHeader.classList.add('active');
            current = currentHeader;
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [topLimit, ultimateOffset, offset]);

    useEffect(() => {
        loadData(KeyAPI);
    }, []);

    const loadData = () => {
        setIsLoadFinish(false);
        const _header = {
            'Content-Type': 'application/json',
            'apikey': KeyAPI
        }
        _fetchData(HOSTNAME, 'v2/booking/services', '', 'GET', _header).then(apiResult => {
            console.log("apiResult",apiResult);
            if(apiResult!=null)
            {
                setDataSource(apiResult.data);
            }
           
            setIsLoadFinish(true);
        })
    }

    return (
        !!isLoadFinish && <>
            <section className="main services">
                <div className="container-fluid groupservices">
                         <div  id="services-tabs"  className={isServicesTabsFixed ? "row servicesbox servicesbox-tabs services-tabs-fixed" : "row servicesbox servicesbox-tabs"}>
                         <ul ref={onePageNavRef} className="st-onepage-nav">
                            {
                                 dataSource.map((item, index) => {
                                    return (
                                        <li key={'dataSource'+index}><a className="st-smooth-move" href={'#row'+index} >{item.serviceGroupName}</a></li>
                                    )
                                })
                            }
                         </ul>
                     </div>
                    
                    {
                        dataSource.map((item, index) => {
                            return (
                                index % 2 === 0 ?
                                    <div key={index} id={'row'+index}>
                                        <div className="row servicesbox" >
                                            <div className="col-12 col-md-5 col-lg-4 hidden_m">
                                                <div className="servicesimg">
                                                    <img src={item.serviceGroupIcon} alt="manicure pedicure" />
                                                    <img style={{ width: 130, height: 130 }} className="servicesimg2" src={item.serviceGroupIcon1} alt="manicure pedicure" />
                                                </div>
                                            </div>

                                            <div className="col-12 hidden_desktop">
                                                <div className="servicesimg-mobile" style={{ backgroundImage: `url('http://nailapi.connectsales.vn/uploaded/product/5260307e-04c8-4945-a5c1-377f5d73024f.jpeg')` }}>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-7 col-lg-8 servicescontent">
                                                <div className="servicestitle">{item.serviceGroupName}</div>
                                                <ul className="lstservice">
                                                    {
                                                        item.services.map((service, ind) => {
                                                            return (
                                                                <li className="item" key={ind}>
                                                                    {service.serviceName}
                                                                    <div className="price">${service.servicePrice}</div>
                                                                    <div className="note f-s-d">
                                                                        <p>
                                                                        {service.description}
                                                                        </p>
                                                                        </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="borderservices">
                                            <img src={require('../assets/images/bg_services.png')} alt="" />
                                        </div>
                                    </div>
                                    :
                                    <div key={index} id={'row'+index}>
                                        <div className="row servicesbox servicesbox-img-right">
                                            <div className="col-12 col-md-7 col-lg-8 servicescontent">
                                                <div className="servicestitle">{item.serviceGroupName}</div>
                                                <ul className="lstservice">
                                                    {
                                                        item.services.map((service, ind) => {
                                                            return (
                                                                <li className="item" key={ind}>
                                                                    {service.serviceName}
                                                                    <div className="price">${service.servicePrice}</div>
                                                                    <div className="note f-s-d">
                                                                        <p>
                                                                        {service.description}
                                                                        </p>
                                                                        </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col-12 col-md-5 col-lg-4 hidden_m">
                                                <div className="servicesimg">
                                                    <img src={item.serviceGroupIcon} alt="manicure pedicure" />
                                                    <img style={{ width: 130, height: 130 }} src={item.serviceGroupIcon1} alt="manicure pedicure" className="servicesimg2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            )
                        })
                    }
                </div>
            </section>
        </>
    );
}
export default Home;